export default [
  {
    header: "Konten",
  },
  {
    title: "Blog",
    icon: "BookOpenIcon",
    children: [
      {
        title: "List",
        route: "pages-blog-list",
      },
      {
        title: "Tambah",
        route: "pages-blog-create",
      },
    ],
  },
];
